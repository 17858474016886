<template>

<section class="">

<header class="pb-0 mb-0">
<nav class="navbar navbar-expand-sm navbar-dark bg-dark d-flex justify-content-between mb-0 pb-1">
   <div class="container-fluid d-flex justify-content-between">
    <img src="./assets/logo.png" class="ps-2" alt="">
      <button class="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarDark" aria-controls="navbarDark" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarDark">
        <ul class="navbar-nav me-auto mb-2 ms-3 mb-xl-0 text-center">
           <li class="nav-item me-3">
              <a class="nav-link" aria-current="page" href="">
                <router-link v-if="accessToken" :to="{ name: 'personal' }" class="nav-link tex p-0 m-0" >
                <div class="nnav2 d-column text-center justify-content-center">
                  <img class="hov_d" :src="image"/> 
                  <img class="hov_a ps-3" :src="image11"/> 
                  <p class="m-0 fw-semibold">Заявки </p>
                </div></router-link>
              </a>
           </li>
          <!-- <li class="nav-item">
              <a class="nav-link" href="#"> <router-link v-if="accessToken" :to="{ name: 'user.personal' }" class="nav-link p-0 tex fs-5 ">Личный кабинет</router-link></a>
           </li>-->
           <li class="nav-item text-center align-items-center justify-content-center d-flex">
            <a v-if="accessToken" href="#" @click.prevent="logout" class="nav-link text-center tex p-0 ">
              <div class="nnav d-column text-center align-items-center">
                <img class="hov_b " :src="image2"/>
                <img class="hov_c ps-3" :src="image22"/>
                 <p class="m-0 fw-semibold">Выход</p></div>
            </a>
           </li>
        </ul>
      
      
        <div class="pe-2">
          <a class="tex nav-link header-text text-center  pt-0 fw-semibold pb-2" href="mailto:Help@ritsgroup.ru">Help@ritsgroup.ru</a>
          <a class="tex nav-link header-text text-center fw-semibold" href="tel:+7-843-253-16-36">+7-843-253-16-36</a>
          <p class="tex2 header-text text-center mb-0">Пн-Пт с 9:00 до 18:00</p>
          
                
          </div>
        </div>
   </div>
</nav>
</header>        

  <!--<router-link v-if="accessToken" :to="{ name: 'personal' }" class="btn btn-primary ms-5" >Заявки</router-link>-->
 <!-- <router-link v-if="!accessToken" :to="{ name: 'user.login' }" class="">Выполните вход</router-link> -->
 <!-- <router-link v-if="!accessToken" :to="{ name: 'user.registration' }" class="btn btn-primary ms-3">Registration</router-link> -->
  <!--<router-link v-if="accessToken" :to="{ name: 'user.personal' }" class="btn btn-primary ms-3">Личный кабинет</router-link>-->
 
  <!--<a v-if="accessToken" href="#" @click.prevent="logout" class="btn btn-primary ms-3">Выйти</a>-->
 
  <router-view :key="$route.fullPath" ></router-view>

 

 <!-- <nav class="navbar fixed-bottom navbar-light bg-light m-0 p-0">
   <div class="container-fluid m-0 p-0 ps-2">
    <p class="tex m-0 p-0">© «RITS (Robotic IT Service)» 2023. Все права защищены</p>
   </div>
</nav>-->


</section>


</template>

<script>
import api from './api'
import image from "./assets/zayav.png"
import image11 from "./assets/zayav2.png"
import image2 from "./assets/exit.png"
import image22 from "./assets/exit2.png"

export default {
  
  data() {
    return {
      accessToken: null,
      image: image,
      image11: image11,
      image2: image2,
      image22: image22,
    }
  }, 

  mounted() {
    this.getaccessToken()
  },

  updated() {
    this.getaccessToken()
  },


  methods: {
    getaccessToken() {
      this.accessToken = localStorage.getItem('accessToken')
    },

    logout() {
      api.get('https://backend.ritsgroup.ru/api/auth/glpi/logout')
      // eslint-disable-next-line
      .then( res => {
        localStorage.removeItem('accessToken');
        this.$router.push({ name: 'user.login' })
      })
    }
  }
  
  
}
</script>

<style scoped>

.navbar {
  background-color: #13163C !important;
}

.tex:hover {
color: #ff6700 !important;
}
.tex {
  color: #8589BC;
}

.tex2 {
  color: #8589BC;
  font-size: 13px;
}


.tex:focus {
color: #ff6700 !important;
}

.nnav2 .hov_a {
    display: none;
   }
   .nnav2:hover .hov_d {
    display: none;
   }
   .nnav2:hover .hov_a {
    display: block;
   }


.nnav .hov_c {
    display: none;
   }
   .nnav:hover .hov_b {
    display: none;
   }
   .nnav:hover .hov_c {
    display: block;
   }

   .nnav {
   text-align: center;
   }

   .nnav2 {
   text-align: center;
   }

</style>
