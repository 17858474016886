<template>
    <section class="main" style="background-color: #13163C;">
    <h3 class="text-center" style="color: #ff6700;">Выполните вход</h3>
    <div class="container d-flex align-items-center justify-content-center mt-3">
        
    <div class="vw-50 text-center">
        <form>
        <input v-model='login' type="login" class="form-control mt-3 mb-3" placeholder="Логин">
            <div class="input-group">
                <input v-model='password' type="password" class="form-control" v-show="!showPass" placeholder="Пароль">
                <input v-model='password' type="text" class="form-control rounded-start" v-show="showPass" placeholder="Пароль" >
                    <div class="input-group-text p-0">
                     <a @click.prevent="showPass = !showPass" class="p-1">

                        <span v-show="!showPass"><img class="" :src="image"/></span>
                        <span v-show="showPass"><img class="" :src="image2"/></span>
                     </a>
                    </div>
            </div>
        <div v-if="error" class=" pb-1" style="color: #ff6700;">Неверный логин или пароль</div>
        <button :disabled="!isDisabled" @click.prevent="loginy" type="submit" class="btn mt-2" 
        style="background-color: #ff6700; color: #fff; border-color: #ff6700;">Войти</button>
    </form>
    </div>

   
    
  
  

   

</div>
<div class="after d-flex" style="height: 310px; background-color: #13163C;"></div>

<nav class="nn navbar fixed-bottom navbar-light bg-light m-0 p-0">
   <div class="container-fluid m-0 p-0 ps-2" style="background-color: #13163C;">
    <p class="tex m-0 p-0">© «RITS (Robotic IT Service)» 2023. Все права защищены</p>
   </div>
</nav>
</section>
</template>

<script>
import axios from 'axios';
import image from "/src/assets/eye1.png";
import image2 from "/src/assets/eye2.png";
import image3 from "/src/assets/waves.png";

export default {
    
    data () {

        

        return {
            login: null,
           password: null,
           error: null,
           showPass:false,
           image: image,
           image2: image2,
           image3: image3,
           
        }
    },
    methods: {
        loginy() {
            axios.post('https://backend.ritsgroup.ru/api/auth/glpi/login', {login: this.login, password: this.password})
            .then( res => {
                localStorage.accessToken = res.data.accessToken
                this.$router.push({ name:'personal' })
            })
            .catch( error => {
                this.error = error.response.data.error
            })
        },

        

    },
    computed: {
        isDisabled() {
            return this.login && this.password
        }
    }
    
}


</script>


<style scoped>

.main {
    height: 100vh;
    padding-top: 60px;
    
}
 .after {
    background-image: url("https://ritsgroup.ru/prog/assets/css/img/waves.png");
 }

 .tex {
  color: #8589BC;
}


</style>