import axios from "axios";
import router from "./router";
/* eslint-disable */


    const api = axios.create();

        //start request

        api.interceptors.request.use(config => {

          if(localStorage.getItem('accessToken')) {
            config.headers = {
            'authorization': `Bearer ${localStorage.getItem('accessToken')}`
            };
          }

          return config
          
        }, error => {})

        //end request
        //start response
        api.interceptors.response.use( {}, error => {
            if (error.response.status === 401) {
              router.push({ name:'user.login' })
            }
        })

        export default api