import { createRouter, createWebHistory } from "vue-router";
import TheLogin from "./components/User/TheLogin.vue";
//import TheRegistration from "./components/User/TheRegistration.vue";
//import ThePersonal from "./components/User/ThePersonal.vue";



const router = /*new*/ createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/tickets', component: () => import('./TheComponentOne.vue'), name:'personal' },
        { path: '/tickets/:id', component: () => import('./components/User/ShowTicket.vue'), name:'show.ticket' },
        { path: '/tickets/:id/messages', component: () => import('./components/User/ChatTicket.vue'), name:'chat' },
        { path: '/users.login', component: TheLogin, name: 'user.login' },
     //   { path: '/users.registration', component: TheRegistration, name: 'user.registration' },
       // { path: '/personal.account ', component: ThePersonal, name: 'user.personal' },
      
      // isxod  { path: '/:catchAll(.*)', component: ThePersonal, name: '404' },
      { path: '/:catchAll(.*)', component: () => import('./TheComponentOne.vue'), name: '404' },
    ]
})

router.beforeEach( ( to, from, next) => {

    const accessToken = localStorage.getItem('accessToken')

    if (!accessToken) {
        if (to.name === 'user.login' || to.name === 'user.registration') {
            return next()
        } else {
            return next ( {
              name: 'user.login'
            })
        }
    }

    //if (to.name !== 'user.login' || to.name !== 'user.registration') {
      //  if (!accessToken) {
      //      return next ( {
      //          name: 'user.login'
     //       })
     //   }
   // }

    if (to.name === 'user.login' || to.name === 'user.registration' && accessToken) {
        return next ( {
            name: 'user.personal'
        })
    }

    next();
})


export default router
