import { createApp } from 'vue';
import App from './App.vue'
import Router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
import VueChatScroll from 'vue3-chat-scroll';
import moment from 'moment';



import axios from 'axios';
import VueAxios from 'vue-axios';

const app = createApp(App);
app.use(Router);
app.use(VueAxios, axios)
app.use(bootstrap).mount('#app');
app.use(VueChatScroll);
app.use(moment);




